import { useState } from "react";
import * as React from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../redux/utils/utilsSlice";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import urls from "../../utils/urls.json";
import { saveUserCredentials } from "../../utils/helpers";
import { DOMAIN } from "../../utils/config";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField as TextFieldBase,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// CAROUSEL
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import carousel1 from "../../assets/carousel1.svg";
import carousel2 from "../../assets/carousel2.svg";
import carousel3 from "../../assets/carousel3.svg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    heading: "START TRIALS EASILY",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",
    imgPath: carousel1,
  },
  {
    heading: "ACCESS ALL YOUR TRIALS AT ONE PLACE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",
    imgPath: carousel2,
  },
  {
    heading: "DOWNLOAD SOPS AND SUBJECT INFO TABLE AT EASE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",

    imgPath: carousel3,
  },
];

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // CAROUSEL
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // redux
  const dispatch = useDispatch();

  // react-router-dom navigator
  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/auth/login/`,
        data: {
          email,
          password,
        },
      });
      // save user credentials
      saveUserCredentials(response.data);
      // redirect to home page

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Logged in Successfully!",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigator(urls.home.path);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        // set error
        setError(error_object.non_field_errors[0]);
      } catch {
        // default error message
        setError(["Unable to log in with provided credentials"]);
      }
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setError(null);
    func(value);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          px={2}
          style={{
            height: "100vh",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            py={5}
            style={{
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Typography
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Log In
            </Typography>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Welcome back!
            </Typography>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Stack my={2} direction="column" spacing={2}>
                <FormControl>
                  <InputLabel shrink variant="standard">
                    Enter Your Email ID
                  </InputLabel>
                  <TextField
                    error={error ? true : false}
                    helperText={error}
                    fullWidth
                    variant="filled"
                    placeholder="Email ID"
                    type="email"
                    value={email}
                    onChange={(e) => handleChange(setEmail, e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    required
                  />
                </FormControl>
                <FormControl>
                  <InputLabel shrink variant="standard">
                    Enter Your Password
                  </InputLabel>
                  <TextField
                    error={error ? true : false}
                    helperText={error}
                    fullWidth
                    variant="filled"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => handleChange(setPassword, e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    required
                  />
                </FormControl>
                <Typography
                  component={Link}
                  to={urls.forgotPassword.path}
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    letterSpacing: "0em",
                    textAlign: "right",
                    color: "#2D81E3",
                  }}
                >
                  Forgot Password?
                </Typography>
                <Button
                  type="submit"
                  disableElevation
                  disabled={loading}
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ textTransform: "none" }}
                >
                  {loading ? "Logging In" : "Log In"}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        style={{
          backgroundColor: "#0B3A73",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ backgroundColor: "transparent" }}
            />
            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "19px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {images[activeStep].heading}{" "}
              </Typography>
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {images[activeStep].description}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
