// Save User Credentials
export const saveUserCredentials = (data) => {
  // helper function to save user credentials to the local storage
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  localStorage.setItem("user", JSON.stringify(data.user));
};

// Delete User Credentials
export const deleteUserCredentials = (data) => {
  // helper function to delete user credentials from local storage
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
};

// Get User Credentials
export const getUserCredentials = () => {
  // helper function to fetch user credentials from local storage
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = {};
  }
  return {
    accessToken: localStorage.getItem("access_token"),
    refreshToken: localStorage.getItem("refresh_token"),
    user,
  };
};

export const getAuthorization = () => {
  const data = getUserCredentials();
  return `Bearer ${data["accessToken"]}`;
};

export const formatCellData = (cell) => {
  function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  if (typeof cell === "boolean") {
    return cell ? "✅" : "❌";
  }
  if (typeof cell === "number") {
    return cell;
  }
  if (cell === null) {
    return "Not performed yet";
  }
  if (isValidDate(cell)) {
    return new Date(cell).toDateString();
  }
  return cell;
};
