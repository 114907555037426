import { Paper, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Table from "../../../components/Table";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";

function Timeline({ session_key }) {
  const [timeline, setTimeline] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [error, setError] = useState();

  const loadingScreen = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  const timelineCols = [
    {
      name: "Test ID",
      accessor: "id",
    },
    {
      name: "Used On",
      accessor: "last_used_on",
    },
    {
      name: "Status",
      accessor: "status",
    },
    {
      name: "Z3Pulse Test ID",
      accessor: "z3pulse_test_id",
    },
  ];

  function getPagination(count) {
    let btns = [];
    let x = count / 8,
      y = count % 8;

    if (y !== 0) count = x + 1;
    else count = x;

    for (let i = 1; i <= count; i++) {
      btns.push(
        <button
          key={i}
          onClick={() => setPage(i)}
          style={{ marginRight: "3px", cursor: "pointer" }}
        >
          {i}
        </button>
      );
    }
    return btns;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios({
          method: "GET",
          url: `${DOMAIN}/account/manager/usage-timeline/${session_key}`,
          headers: {
            Authorization: getAuthorization(),
          },
          params: { page_size: 8, page },
        });
        setTimeline(response.data);
      } catch (error) {
        if (error.request) {
          setError(JSON.parse(error.request.response));
        } else {
          setError(error);
        }
      }
      setLoading(false);
    })();
  }, [session_key, page]);

  return (
    <>
      {error && <p style={{ color: "crimson" }}>{JSON.stringify(error)}</p>}
      {loading ? (
        <div style={loadingScreen}>loading...</div>
      ) : (
        <>
          <h3 style={{}}>Test Timeline</h3>
          <hr />
          <Table cols={timelineCols} rows={timeline.results} />

          <center>
            {console.log(timeline.count)}
            {getPagination(timeline.count).map((btn) => btn)}
          </center>
        </>
      )}
    </>
  );
}

function InternalContent({ email }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [user, setUser] = useState(null);

  const content = {
    paddingLeft: "10px",
    paddingRight: "10px",
    position: "relative",
    maxHeight: "100vh",
  };
  const loadingScreen = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };
  const h4 = { margin: 0, textAlign: "center" };

  const handleOpenDialog = (row) => {
    setUser(users[row]);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios({
          method: "GET",
          url: DOMAIN + "/account/manager/user/usage",
          params: { email: email },
          headers: {
            Authorization: getAuthorization(),
          },
        });
        setUsers(response.data.results);
        setExtraData(response.data.extra_data);
      } catch (error) {
        if (error.request) {
          setError(JSON.parse(error.request.response));
        } else {
          setError(error);
        }
      }
      setLoading(false);
    })();
  }, [email]);

  const usersCols = [
    {
      name: "Session Key",
      accessor: "session_key",
    },
    {
      name: "Status",
      accessor: "status",
    },
    {
      name: "Total Tests",
      accessor: "total_tests",
    },
    {
      name: "Tests Remaining",
      accessor: "tests_remaining",
    },
  ];

  return (
    <div style={content}>
      {error && <p style={{ color: "crimson" }}>{JSON.stringify(error)}</p>}
      {loading ? (
        <div style={loadingScreen}>loading...</div>
      ) : (
        <>
          <div>
            <h4 style={{}}>
              Tests assigned:
              <span style={{ fontWeight: "450", marginLeft: "5px" }}>
                {extraData.total_tests_assigned}
              </span>
            </h4>
            <h4 style={{}}>
              Tests completed:
              <span style={{ fontWeight: "450", marginLeft: "5px" }}>
                {extraData.tests_completed}
              </span>
            </h4>
            <h4 style={{}}>
              Tests failed:{" "}
              <span style={{ fontWeight: "450", marginLeft: "5px" }}>
                {extraData.tests_failed}
              </span>
            </h4>
            <h4 style={{}}>
              Tests Remaining:
              <span style={{ fontWeight: "450", marginLeft: "5px" }}>
                {extraData.total_tests_assigned -
                  (extraData.tests_failed + extraData.tests_completed)}
              </span>
            </h4>
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
              <Table
                cols={usersCols}
                rows={users}
                openDialog={handleOpenDialog}
              />
            </div>
            <div
              style={{
                width: "50%",
                padding: "15px",
                border: "1px solid #000",
              }}
            >
              {user ? (
                <Timeline session_key={user.session_key} />
              ) : (
                <h4 style={h4}>
                  Select a row to view usage timeline and other details
                </h4>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function UserTimeline({ dispatch }) {
  const [email, setEmail] = useState("");
  const [showInternalComp, setShowInternalComp] = useState(false);

  return (
    <Paper sx={{backgroundColor:"#fafafa", padding:"30px", borderRadius:"5px"}} elevation={0}>
        <div style={{ overflowX: "hidden" }}>
      <div>
        <Typography
          style={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "38px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          User Usage
        </Typography>
        <center>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: "320px",
              height: "30px",
              fontSize: "18px",
            }}
            type="email"
            placeholder="enter the email..."
          />
          <button
            type="button"
            onClick={() => setShowInternalComp(!showInternalComp)}
          >
            Fetch data
          </button>
        </center>

        {showInternalComp && <InternalContent email={email} />}
      </div>
    </div>

    </Paper>
  
  );
}

export default UserTimeline;
