import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

export default function CustomDialog({
  open,
  setOpen,
  title,
  content,
  action,
  actionText,
}) {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle p="32px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            style={{
              fontSize: "32px",
              fontWeight: "700",
              lineHeight: "38px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#2D2D2D",
            }}
          >
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpen(false)}
          >
            <Close fontSize="medium" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {action && (
        <DialogActions>
          <Button onClick={action}>{actionText}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
