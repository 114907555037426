import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";

import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CustomDialog from "../../../components/CustomDialog";
import { CustomSelect } from "../../../components/CustomSelect";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import NoData from "../../../components/Blockers/NoData";
import AddIcon from "@mui/icons-material/Add";

import chart from "../../../assets/Chart.svg";
import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";

export default function AddEntry(props) {
  const [addEntryDialog, setAddEntryDialog] = useState(false);

  const [skuList, setSkuList] = useState([]);

  const [sku, setSku] = useState(0);
  const [units, setUnits] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/orders/fetch-skus/`,
    })
      .then((res) => {
        setSkuList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleChange = (func, event) => {
    func(event.target.value);
  };

  // redux
  const dispatch = useDispatch();

  const handleAddEntry = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/orders/place-order/z3pulse/`,
        data: {
          sku,
          payment_mode: "cheque",
          quantity: Math.floor(units),
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully requested order",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      // reload window if suceeded
      window.location.reload();
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.detail);
        // set error
        setError(error_object.detail);
      } catch {
        // default error message
        setError("Error while requesting order");
      }
    }
  };

  return (
    <>
      {props.isNew ? (
        <NoData
          message="Looks like you haven't requested any yet"
          actionText="Create an Order now"
          action={() => setAddEntryDialog(true)}
        />
      ) : (
        <Paper
          onClick={() => setAddEntryDialog(true)}
          elevation={0}
          style={{
            padding: "21px",
            backgroundColor: "#FFFFFF",
            border: "1px solid #F7F7F7",
            borderRadius: "6px",
            cursor: "pointer",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <img src={chart} alt="icon" />
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#4A4A4A",
                }}
              >
                Create an Order
              </Typography>
            </Stack>
            <IconButton>
              <AddIcon />
            </IconButton>
          </Stack>
        </Paper>
      )}

      <CustomDialog
        open={addEntryDialog}
        setOpen={setAddEntryDialog}
        title="Create Order"
        content={
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={6}
            pt="15px"
          >
            <form autoComplete="off" onSubmit={handleAddEntry}>
              <Stack my={2} direction="column" spacing={2}>
                <FormControl>
                  <CustomSelect
                    fullWidth
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={sku}
                    onChange={(e) => handleChange(setSku, e)}
                  >
                    <MenuItem value={0}>Select SKU</MenuItem>
                    {skuList.map((sku, index) => (
                      <MenuItem key={index} value={sku.id}>
                        {sku.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
                <FormControl>
                  <CustomInputLabel label="Enter number of units" />
                  <CustomTextField
                    fullWidth
                    variant="filled"
                    placeholder="eg. 1"
                    type="number"
                    value={units}
                    onChange={(e) => handleChange(setUnits, e)}
                    InputProps={{ disableUnderline: true }}
                  />
                </FormControl>
                {error && (
                  <Typography variant="caption" color="red">
                    {error}
                  </Typography>
                )}
                <Button
                  disabled={loading}
                  disableElevation
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  {loading ? "Submitting" : "Submit"}
                </Button>
              </Stack>
            </form>
          </Stack>
        }
      />
    </>
  );
}
