// 3rd party modules
import { Link } from "react-router-dom";

// material ui hooks
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { logout } from "../redux/auth/authSlice";
import { setNotify } from "../redux/utils/utilsSlice";

// material ui modules
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// material ui icons
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WarningIcon from "@mui/icons-material/Warning";
// utils

import urls from "../utils/urls.json";

// images
import dashboard_icon from "../assets/sidebar/trial-icon.svg";
import profile_icon from "../assets/sidebar/profile-icon.svg";
import logo from "../assets/sidebar/poweredby.svg";

const sidebar_links = [
  {
    name: "Dashboard",
    code: "dashboard",
    icon: (
      <img
        src={dashboard_icon}
        alt="dashboard-icon"
        style={{ marginRight: "14px", width: "24px" }}
      />
    ),
    url: urls.home.path,
  },
  {
    name: "Send Session",
    code: "assignSession",
    icon: <DashboardIcon style={{ marginRight: "14px", width: "24px", color: "#FFFFFF" }} />,
    url: urls.assignSession.path,
  },
  {
    name: "Analytics",
    code: "analytics",
    icon: (
      // <img
      //   src={dashboard_icon}
      //   alt="analytics"
      //   style={{ marginRight: "14px", width: "24px" }}
      // />
      <AutoGraphIcon
        style={{ marginRight: "14px", width: "24px", color: "#FFFFFF" }}
      />
    ),
    url: urls.analytics.path,
  },
  {
    name: "Issues",
    code: "issues",
    icon: (
      <WarningIcon
        style={{ marginRight: "14px", width: "24px", color: "#FFFFFF" }}
      />
    ),
    url: urls.issues.path,
  },
  {
    name: "Profile",
    code: "profile",
    icon: (
      <img
        src={profile_icon}
        alt="profile-icon"
        style={{ marginRight: "14px", width: "24px" }}
      />
    ),
    url: urls.profile.path,
  },
];

export default function SideBar({ drawerWidth, open, setOpen, children }) {
  const auth = useSelector((state) => state.auth);
  const utils = useSelector((state) => state.utils);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { lg: drawerWidth },
          minHeight: "100vh",
        }}
        className="Sidebar-Component"
      >
        <Drawer
          variant={matches ? "permanent" : "temporary"}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,

              background: "#00162F",
            },
          }}
          open={matches ? open : !open}
          onClose={() => setOpen(!open)}
        >
          <Box
            sx={{
              width: "80%",
              mx: "auto",
              pt: "36px",
              pb: "26px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "25px",
                lineHeight: "39px",
                color: "#FFFFFF",
              }}
              mt={1}
            >
              Sleepfit Management Portal
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexFlow: "nowrap column",
              justifyContent: "space-between",
              height: "calc(100vh - 158px)",
            }}
          >
            <Stack
              sx={{ mt: "32px" }}
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              {sidebar_links.map((element, index) => (
                <Box
                  sx={{
                    width: "100%",
                    p: "16px 20px",
                  }}
                  key={`sidebar-element-${index}`}
                >
                  <MenuItem
                    component={Link}
                    to={element.url}
                    sx={{
                      width: "100%",
                      padding: "0",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        "&:hover": {
                          backgroundColor: "#0B3A73",
                        },
                        p: "16px 38px",
                        width: "100%",
                        borderRadius: "6px",

                        backgroundColor:
                          utils.activeTab === element.code
                            ? "#0B3A73"
                            : "transparent",
                      }}
                    >
                      {element.icon}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{
                          color: "#FFF",
                          fontSize: "18px",
                        }}
                        noWrap
                      >
                        {element.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                </Box>
              ))}
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack width="100%" spacing={5}>
                {auth.isAuthenticated ? (
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // logout on click
                      dispatch(logout());

                      // notify
                      dispatch(
                        setNotify({
                          open: true,
                          action: "Logged out Successfully!",
                          severity: "success",
                          autoHideDuration: 5000,
                          vertical: "bottom",
                          horizontal: "right",
                        })
                      );

                      // redirect to login page
                      navigate(urls.login.path);
                    }}
                  >
                    <PowerSettingsNewIcon
                      fontSize="medium"
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    />
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color: "#FFF",
                        fontSize: "18px",
                      }}
                      noWrap
                    >
                      Logout
                    </Typography>
                  </Stack>
                ) : null}
                <Stack justifyContent="center" alignItems="center">
                  <img src={logo} alt="logo" />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  );
}
