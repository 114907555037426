import { useState } from "react";
import { useSelector } from "react-redux";

// material ui modules
import Box from "@mui/material/Box";

// components
import Sidebar from "../../components/Sidebar";
import Notify from "../../components/Notify";

// Layout Component: Defines the structure of the application
function Layout({ children }) {
  const DRAWER_WIDTH = 292;
  const [open, setOpen] = useState(true);
  const auth = useSelector((state) => state.auth);

  return (
    <Box sx={{ display: "flex" }} className="Layout-Component">
      {auth.isAuthenticated ? (
        <Sidebar drawerWidth={DRAWER_WIDTH} open={open} setOpen={setOpen} />
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { lg: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
        className="Layout-Main-Area"
      >
        <Box
          className="Layout-Main-Area-Body"
          sx={{ height: "calc(100% - 90px)" }}
        >
          {children}
        </Box>
      </Box>
      <Notify />
    </Box>
  );
}

export default Layout;
