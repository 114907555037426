import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./hoc/ProtectedRoute/Index";
import RedirectIfAuth from "./hoc/RedirectIfAuth/Index";

import urls from "../src/utils/urls.json";

// components
import Layout from "./containers/Layout/Index";

// pages
import Dashboard from "./containers/Pages/Dashboard";
import AssignSession from "./containers/Pages/AssignSession";
import Analytics from "./containers/Pages/Analytics";
import Issues from "./containers/Pages/Issues";
import Issue from "./containers/Pages/Issue";
import Profile from "./containers/Pages/Profile";
import Login from "./containers/Auth/Login";
import ForgotPassword from "./containers/Auth/ForgotPassword";

export default function MyRoutes() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route
            path={urls.home.path}
            element={<ProtectedRoute children={<Dashboard />} />}
          />
          <Route
            path={urls.analytics.path}
            element={<ProtectedRoute children={<Analytics />} />}
          />
          <Route
            path={urls.issues.path}
            element={<ProtectedRoute children={<Issues />} />}
          />
          <Route
            path={urls.issue.path}
            element={<ProtectedRoute children={<Issue />} />}
          />
          <Route
            path={urls.assignSession.path}
            element={<ProtectedRoute children={<AssignSession />} />}
          />
          <Route
            path={urls.profile.path}
            element={<ProtectedRoute children={<Profile />} />}
          />
          <Route
            path={urls.forgotPassword.path}
            element={<RedirectIfAuth children={<ForgotPassword />} />}
          />
          <Route
            path={urls.login.path}
            element={<RedirectIfAuth children={<Login />} />}
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
