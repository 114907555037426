import { useEffect, useState } from "react";
import { CircularProgress, Paper, Stack, Typography } from "@mui/material";

import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

import { getAuthorization } from "../../../utils/helpers";
import { DOMAIN } from "../../../utils/config";
import axios from "axios";

import EditDate from "./EditDate";
import EditDaysAndTests from "./EditDaysAndTests";

const CARDS = [
  {
    id: 1,
    loading: true,
    title: "Active Sessions",
    description:
      "Here are the number of sessions that are marked as active in the entire system till date.",
    actions: [],
  },
  {
    id: 2,
    loading: true,
    title: "Tests Taken on (n)th day",
    edit: {
      action: EditDate,
    },
    description:
      "Here are the tests taken count for the given test date and returns the tests that are processed/started/failed for the particular test date.",
    actions: [],
  },
  {
    id: 3,
    loading: true,
    title: "Tests Taken in last n day(s)",
    edit: {
      action: EditDaysAndTests,
    },
    description:
      "How many people took <x> no. of test in last <y> number of day(s) compared to users with active Sessions",
    actions: [],
  },
];

export default function TestAndSessionCards() {
  const [cards, setCards] = useState(CARDS);
  const [loading1, setLoading1] = useState(null);
  const [loading2, setLoading2] = useState(null);
  const [loading3, setLoading3] = useState(null);
  const [days, setDays] = useState(1);
  const [testers, setTesters] = useState(1);

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("analytics"));
  }, [dispatch]);

  // Fetch Active Sessions
  useEffect(() => {
    if (loading1 === null) {
      setLoading1(true);
      // START: axios req
      axios({
        method: "GET",
        url: `${DOMAIN}/account/manager/tests/summary`,
        headers: {
          Authorization: getAuthorization(),
        },
      })
        .then((res) => {
          // id 1
          const id1 = cards.filter((i) => i.id === 1)[0];
          id1.actions = [
            {
              title: "Active Session",
              value: res.data.active_sessions,
            },
          ];
          id1.loading = false;

          // id 2
          const id2 = cards.filter((i) => i.id === 2)[0];

          // id 3
          const id3 = cards.filter((i) => i.id === 3)[0];

          // saving updated card items
          setLoading1(false);
          setCards([id1, id2, id3]);
        })
        .catch((err) => console.log(err));
      // END: axios req
    }
  }, [setLoading1, cards, loading1]);

  // Fetch Test Taken on a given date | DEFAULT today
  useEffect(() => {
    if (loading2 === null) {
      setLoading2(true);
      // START: axios req
      axios({
        method: "GET",
        url: `${DOMAIN}/account/manager/tests/summary`,
        headers: {
          Authorization: getAuthorization(),
        },
      })
        .then((res) => {
          // id 1
          const id1 = cards.filter((i) => i.id === 1)[0];

          // id 2
          const id2 = cards.filter((i) => i.id === 2)[0];
          id2.actions = [
            {
              title: "Test Taken",
              value: res.data.tests_taken,
            },
          ];
          id2.loading = false;

          // id 3
          const id3 = cards.filter((i) => i.id === 3)[0];

          // saving updated card items
          setLoading2(false);
          setCards([id1, id2, id3]);
        })
        .catch((err) => console.log(err));
      // END: axios req
    }
  }, [setLoading2, cards, loading2]);

  // Fetch Test Taken in last n days | DEFAULT 1 day, 1 test
  useEffect(() => {
    if (loading3 === null) {
      setLoading3(true);
      // START: axios req
      axios({
        method: "GET",
        url: `${DOMAIN}/account/manager/tests/usage/summary`,
        params: {
          number_of_tests: testers,
          number_of_days: days,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      })
        .then((res) => {
          // id 1
          const id1 = cards.filter((i) => i.id === 1)[0];

          // id 2
          const id2 = cards.filter((i) => i.id === 2)[0];

          // id 3
          const id3 = cards.filter((i) => i.id === 3)[0];
          id3.actions = [
            {
              title: "Users",
              value: res.data.users,
            },
            {
              title: "Sessions",
              value: res.data.active_sessions,
            },
          ];
          id3.loading = false;

          // saving updated card items
          setLoading2(false);
          setCards([id1, id2, id3]);
        })
        .catch((err) => console.log(err));
      // END: axios req
    }
  }, [setLoading3, cards, loading3, days, testers]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      pt={4}
      spacing={3}
    >
      {cards.map((item, index) => (
        <Paper
          key={index}
          elevation={0}
          style={{
            padding: "15px 20px",
            width: "100%",
            backgroundColor: "#00162f",
            color: "#fff",
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            style={{
              minHeight: "200px",
            }}
          >
            <div>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  mb={1}
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {item.title}
                </Typography>
                {item.edit && (
                  <Stack direction="row" spacing={0.5}>
                    <item.edit.action
                      cards={cards}
                      setCards={setCards}
                      days={days}
                      setDays={setDays}
                      testers={testers}
                      setTesters={setTesters}
                    />
                  </Stack>
                )}
              </Stack>
              <Typography variant="body2" color="#f1f1f1">
                {item.description}
              </Typography>
            </div>
            <Stack
              direction="row"
              justifyContent={item.loading ? "center" : "space-between"}
              alignItems="center"
            >
              {item.loading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    marginBottom: "10px",
                  }}
                />
              ) : (
                item.actions.map((action, actionIndes) => (
                  <Stack key={actionIndes} direction="column">
                    <Typography variant="body1">{action.title}</Typography>
                    <Typography variant="h5">{action.value}</Typography>
                  </Stack>
                ))
              )}
            </Stack>
          </Stack>
        </Paper>
      ))}
    </Stack>
  );
}
