import { useState } from "react";

import {
  Button,
  FormControl,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { Edit } from "@mui/icons-material";

import CustomDialog from "../../../components/CustomDialog";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";

import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";

export default function EditDate(props) {
  const [addEntryDialog, setAddEntryDialog] = useState(false);

  const [date, setDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (func, event) => {
    func(event.target.value);
  };

  const handleEditDate = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/account/manager/tests/summary`,
        params: {
          test_date: date,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // id 1
      const id1 = props.cards.filter((i) => i.id === 1)[0];

      // id 2
      const id2 = props.cards.filter((i) => i.id === 2)[0];
      id2.actions = [
        {
          title: "Test Taken",
          value: res.data.tests_taken,
        },
      ];

      // id 3
      const id3 = props.cards.filter((i) => i.id === 3)[0];

      props.setCards([id1, id2, id3]);
      setLoading(false);
      setAddEntryDialog(false);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.detail);
        // set error
        setError(error_object.detail);
      } catch {
        // default error message
        setError("Error while requesting order");
      }
    }
  };

  return (
    <>
      <Typography variant="body2" style={{ marginTop: "3.5px" }}>
        {date ? date : "Today"}
      </Typography>
      <IconButton
        aria-label="delete"
        size="small"
        color="inherit"
        onClick={() => setAddEntryDialog(true)}
      >
        <Edit fontSize="inherit" color="inherit" />
      </IconButton>
      <CustomDialog
        open={addEntryDialog}
        setOpen={setAddEntryDialog}
        title="Search on Date"
        content={
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={6}
            pt="15px"
          >
            <form autoComplete="off" onSubmit={handleEditDate}>
              <Stack my={2} direction="column" spacing={2}>
                <FormControl>
                  <CustomInputLabel label="Enter Date" />
                  <CustomTextField
                    required
                    fullWidth
                    variant="filled"
                    placeholder="DD-MM-YYYY"
                    type="date"
                    value={date}
                    onChange={(e) => handleChange(setDate, e)}
                    InputProps={{ disableUnderline: true }}
                  />
                </FormControl>
                {error && (
                  <Typography variant="caption" color="red">
                    {error}
                  </Typography>
                )}
                <Button
                  disabled={loading}
                  disableElevation
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  {loading ? "Searching" : "Search"}
                </Button>
              </Stack>
            </form>
          </Stack>
        }
      />
    </>
  );
}
