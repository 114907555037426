import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

import { Grid, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import NoData from "../../../components/Blockers/NoData";
import TabularData from "./TabularData";

function Issues() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("issues"));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/support/issues/`,
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        setData(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Grid
      container
      style={{
        height: "100%",
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      <Loading loading={loading}>
        {data.length ? (
          <>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "#fff",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={3}
                px="58px"
                paddingTop="48px"
                paddingBottom="24px"
                component={Paper}
                elevation={3}
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#fff",
                  zIndex: 101,
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: "32px",
                    fontWeight: "700",
                    lineHeight: "38px",
                    letterSpacing: "0em",
                    textAlign: "left",
                  }}
                >
                  Issues
                </Typography>
              </Stack>
              <Grid
                container
                px="48px"
                py="32px"
                mt={1}
                style={{
                  backgroundColor: "transparent !important",
                  zIndex: 100,
                }}
                spacing={4}
              >
                <TabularData data={data} setData={setData} />
              </Grid>
            </Grid>
          </>
        ) : (
          <NoData message="No issues found" />
        )}
      </Loading>
    </Grid>
  );
}

export default Issues;
