import * as React from "react";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Chip, FormControl, IconButton, Stack } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { CustomTextField } from "../../../components/CustomTextField";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import moment from "moment";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border bottom
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const priorityColor = {
  critical: "error",
  major: "warning",
  moderate: "info",
  low: "success",
};

const typeColor = {
  bug: "error",
  enhancement: "warning",
  task: "info",
};

export default function TabularData({ data, setData }) {
  const navigate = useNavigate();
  const [noteDialog, setNoteDialog] = React.useState(false);
  const [noteData, setNoteData] = React.useState("");
  const [noteId, setNoteId] = React.useState(null);
  const [savingNote, setSavingNote] = React.useState(false);

  const dispatch = useDispatch();

  const saveNote = (e) => {
    e.preventDefault();
    setSavingNote(true);

    axios({
      method: "PATCH",
      url: `${DOMAIN}/support/issues/${noteId}/`,
      data: {
        note: noteData,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        const tempData = data.map((item) => {
          if (item.id === noteId) {
            return {
              ...item,
              note: noteData,
            };
          } else {
            return item;
          }
        });
        setData(tempData);
        setNoteDialog(false);
        setSavingNote(false);
        // notify
        dispatch(
          setNotify({
            open: true,
            action: "Note Saved!",
            severity: "success",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setSavingNote(false);
        // notify
        dispatch(
          setNotify({
            open: true,
            action: "Something went wrong!",
            severity: "error",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          size="small"
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Sr.no</StyledTableCell>
              <StyledTableCell align="left">Title</StyledTableCell>
              <StyledTableCell>Active</StyledTableCell>
              <StyledTableCell>Resolved</StyledTableCell>
              <StyledTableCell>Total Failed</StyledTableCell>
              <StyledTableCell>Created Time</StyledTableCell>
              <StyledTableCell>Priority</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell align="center">Note</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => {
                    navigate(`/issues/${row.id}`);
                  }}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {row.title}
                </StyledTableCell>
                <StyledTableCell>{row.tests.assigned - row.tests.resolved}</StyledTableCell>
                <StyledTableCell>{row.tests.resolved}</StyledTableCell>
                <StyledTableCell>{row.tests.assigned}</StyledTableCell>
                <StyledTableCell>
                  {moment(row.created_at).format("h:mm A (DD MMM, YY)")}
                </StyledTableCell>
                <StyledTableCell>
                  <Chip
                    label={row.priority}
                    color={priorityColor[row.priority]}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Chip label={row.type} color={typeColor[row.type]} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    onClick={() => {
                      setNoteId(row.id);
                      setNoteData(row.note);
                      setNoteDialog(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomDialog
        open={noteDialog}
        setOpen={setNoteDialog}
        title="Note"
        content={
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={6}
            pt="15px"
          >
            <form onSubmit={saveNote} autoComplete="off">
              <Stack my={2} direction="column" spacing={2}>
                <FormControl>
                  <CustomTextField
                    required
                    fullWidth
                    multiline
                    variant="filled"
                    rows={10}
                    InputProps={{ disableUnderline: true }}
                    value={noteData ? noteData : ""}
                    onChange={(e) => setNoteData(e.target.value)}
                  />
                </FormControl>
                <Button
                  disableElevation
                  disabled={savingNote}
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  {savingNote ? "Saveing" : "Save"}
                </Button>
              </Stack>
            </form>
          </Stack>
        }
      />
    </>
  );
}
