import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, Chip } from "@mui/material";

export default function BasicTable({ data, selectedRows, setSelectedRows }) {
  const getStatus = (status) => {
    switch (status) {
      case "test.report.error":
        return "error";
      case "test.report.processing":
        return "processing";
      default:
        return "success";
    }
  };

  const getChipColor = (status) => {
    switch (status) {
      case "test.report.error":
        return "error";
      case "test.report.processing":
        return "warning";
      default:
        return "success";
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={
                  data.length ? selectedRows.length === data.length : false
                }
                onClick={() => {
                  if (selectedRows.length) {
                    return setSelectedRows([]);
                  } else {
                    return setSelectedRows(
                      data.map((item) => {
                        return item.id;
                      })
                    );
                  }
                }}
              />
            </TableCell>
            <TableCell>Test ID</TableCell>
            <TableCell>Z3Pulse Test ID</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length ? (
            data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    checked={Boolean(
                      selectedRows.filter((i) => i === row.id).length
                    )}
                    onClick={() => {
                      const checked = Boolean(
                        selectedRows.filter((i) => i === row.id).length
                      );
                      if (checked) {
                        setSelectedRows(
                          selectedRows.filter((i) => i !== row.id)
                        );
                      } else {
                        setSelectedRows([...selectedRows, row.id]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{row.testId}</TableCell>
                <TableCell>{row.z3pulseTestId}</TableCell>
                <TableCell>
                  <Chip
                    color={getChipColor(row.status)}
                    size="small"
                    label={`${getStatus(row.status)}`}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No Tests Found..
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
