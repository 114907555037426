import { Stack, Button, Typography } from "@mui/material";
import Img from "../../assets/SearchingLaptop.png";

function NoData({ message, actionText, action, height }) {
  return (
    <Stack
      minHeight={height ? height : "90vh"}
      width="100%"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <img src={Img} alt="illustrations" />
      <Typography
        style={{
          paddingTop: "30px",
          fontSize: "20px",
          fontWeight: "400",
          lineHeight: "24px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "#2D2D2D",
        }}
      >
        {message || "No data found"}
      </Typography>

      {action ? (
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={action}
        >
          {actionText || "Try Again"}
        </Button>
      ) : null}
    </Stack>
  );
}

export default NoData;
