import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setActiveTab, setNotify } from "../../../redux/utils/utilsSlice";

import {
  Container,
  Typography,
  Stack,
  Autocomplete,
  Button,
  FormControl,
} from "@mui/material";

import { CustomTextField } from "../../../components/CustomTextField";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";

export default function AssignSession() {
  const [email, setEmail] = useState("");

  const [session, setSession] = useState("");
  const [sessionSearchKey, setSessionSearchKey] = useState("");
  const [sessionOptions, setSessionOptions] = useState([]);
  const [noSessionOptionsText, setNoSessionOptionsText] = useState(
    "Enter atleast 3 characters"
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("assignSession"));
  }, [dispatch]);

  const fetchSessions = async (value) => {
    setNoSessionOptionsText("Searching..");
    const response = await axios({
      method: "GET",
      url: `${DOMAIN}/account/manager/search/`,
      params: {
        search_using: "sessions",
        search_query: value,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    });

    let tempSessionOptions = response.data.map((item) => ({
      key: item.session_key,
      label: item.session_key,
    }));
    setSessionOptions(tempSessionOptions);
    setNoSessionOptionsText("No Session Found");
  };

  const handleChangeSessionSearchKey = async (e) => {
    const value = e.target.value;
    setSessionSearchKey(e.target.value);
    if (value.length > 2) {
      await fetchSessions(value);
    } else {
      setSessionOptions([]);
      setNoSessionOptionsText("Enter atleast 3 characters");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError();

    axios({
      method: "POST",
      url: `${DOMAIN}/account/manager/assign-session/`,
      data: {
        email,
        session_key: session.key,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        // reset local state if suceeded
        setEmail("");

        setSession("");
        setSessionSearchKey("");
        setSessionOptions([]);
        setNoSessionOptionsText("Enter atleast 3 characters");

        setLoading(false);
        setError();
        // notify
        dispatch(
          setNotify({
            open: true,
            action: "Successfully assigned session",
            severity: "success",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        try {
          err = JSON.parse(err.request.response);
          setError(err.detail);
        } catch {
          setError("Error while assigning session");
        }
        setLoading(false);
      });
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "48px",
        backgroundColor: "#fff",
        height: "100vh",
      }}
    >
      <Typography
        style={{
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "38px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        Send Session
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{
            paddingTop: "90px",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Stack my={2} direction="column">
            <FormControl>
              <CustomTextField
                variant="filled"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setError();
                  setEmail(e.target.value);
                }}
                required
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                disablePortal
                options={sessionOptions}
                noOptionsText={noSessionOptionsText}
                autoComplete={true}
                autoHighlight={true}
                value={session}
                onChange={(event, value) => setSession(value)}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="filled"
                    type="text"
                    label="Search Session"
                    value={sessionSearchKey}
                    onChange={(e) => {
                      handleChangeSessionSearchKey(e);
                    }}
                  />
                )}
              />
            </FormControl>
            {error && (
              <Typography variant="caption" color="red">
                {error}
              </Typography>
            )}
            <Button
              disabled={!(email && session) || loading}
              type="submit"
              disableElevation
              size="large"
              color="primary"
              variant="contained"
              style={{ textTransform: "none" }}
            >
              {loading ? "Assigning" : "Assign"}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
