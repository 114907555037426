import PropTypes from "prop-types";
import { formatCellData } from "../utils/helpers";

export default function Table({ cols, rows, openDialog = null }) {
  const table = {
    width: "100%",
    borderCollapse: "collapse",
    textAlign: "left",
    margin: "auto",
  };

  const cell = {
    padding: "8px 5px",
  };

  const handleClick = (event, row) => {
    if (openDialog) {
      return openDialog(rows.indexOf(row));
    }
    return;
  };

  function getCellData(row, accessor) {
    let cellData;
    try {
      cellData = row[accessor];
    } catch (error) {
      cellData = JSON.parse(row[accessor]).action;
    }
    return cellData;
  }

  return (
    <table style={table}>
      <thead>
        <tr>
          {cols.map((col, i) => (
            <th style={cell} key={i}>
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.length === 0 ? (
          <tr aria-colspan={cols.length}>
            <td
              colSpan={cols.length}
              style={{ padding: "10px 0px", fontSize: "14px" }}
            >
              <center>No Entries yet</center>
            </td>
          </tr>
        ) : (
          rows.map((row, i) => (
            <tr key={i} onClick={(event) => handleClick(event, row)}>
              {cols.map((col, i) => (
                <td key={i} style={{ ...cell, fontSize: "14px" }}>
                  {formatCellData(getCellData(row, col.accessor))}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  openDialog: PropTypes.func,
};
