import { Container, Divider, Stack, Typography } from "@mui/material";
import UserTimeline from "./UserTimeline";
import TestAndSessionCards from "./TestAndSessionCards";

export default function Analytics() {
  return (
    <Container
      maxWidth={false}
      style={{
        padding: "48px",
        backgroundColor: "#fff",
        height: "100vh",
      }}
    >
      <Typography
        style={{
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "38px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        Analytics
      </Typography>
      <Stack spacing={3}>
        {/* First Row with Cards */}
        <TestAndSessionCards />
        <Divider />
        {/* User Timeline Table */}
        <UserTimeline />
      </Stack>
    </Container>
  );
}
