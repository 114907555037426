import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

import { Container, Typography, Stack, FormControl, Grid } from "@mui/material";

import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";

import ChangePassword from "./ChangePassword";

export default function Profile() {
  const auth = useSelector((state) => state.auth);
  const [user] = useState(auth.user);

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("profile"));
  }, [dispatch]);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "48px",
        backgroundColor: "#fff",
        height: "100vh",
      }}
    >
      <Typography
        style={{
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "38px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        Profile
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <form
          autoComplete="off"
          style={{
            paddingTop: "90px",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Stack my={2} direction="column" spacing={4}>
            <Grid container>
              <Grid item xs={6} pr={1}>
                <FormControl fullWidth>
                  <CustomInputLabel label="Enter Your First Name" />

                  <CustomTextField
                    disabled
                    fullWidth
                    variant="filled"
                    placeholder="First Name"
                    type="text"
                    value={user["first_name"]}
                    InputProps={{ disableUnderline: true }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} pl={1}>
                <FormControl fullWidth>
                  <CustomInputLabel label="Enter Your Last Name" />
                  <CustomTextField
                    disabled
                    fullWidth
                    variant="filled"
                    placeholder="Last Name"
                    type="text"
                    value={user["last_name"]}
                    InputProps={{ disableUnderline: true }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl>
              <CustomInputLabel label="Enter Your Email ID" />
              <CustomTextField
                disabled
                fullWidth
                variant="filled"
                placeholder="Email ID"
                type="email"
                value={user["email"]}
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <FormControl>
              <ChangePassword />
              <CustomTextField
                disabled
                fullWidth
                variant="filled"
                placeholder="Password"
                value=".........."
                type="password"
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
