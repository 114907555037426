import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

import { Grid, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import AddEntry from "./AddEntry";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("dashboard"));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/orders/place-order/z3pulse/`,
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        setEntries(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const Card = (props) => (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Stack
        direction="column"
        style={{
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.07)",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          padding="24px"
          style={{
            borderRadius: "6px 6px 0px 0px",
          }}
        >
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#2D2D2D",
            }}
          >
            {props.status}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#7E7E7E",
            }}
          >
            {props.z3pulse_sku.name}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#7E7E7E",
            }}
          >
            <b>Validity:</b> {props.z3pulse_sku.days_till_valid} days
          </Typography>
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          padding="24px"
          style={{
            backgroundColor: "#F7F7F7",
            borderRadius: "0px 0px 6px 6px",
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="left"
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#4A4A4A",
              }}
            >
              <b>Total Tests:</b> {props.z3pulse_sku.tests_allocated}
            </Typography>
          </Stack>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#4A4A4A",
            }}
          >
            <b>Created on:</b>{" "}
            {moment(props.created_on).format("D - MMMM - YYYY")}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      style={{
        height: "100%",
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      <Loading loading={loading}>
        {entries.length ? (
          <>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "#fff",
              }}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                spacing={3}
                px="58px"
                paddingTop="48px"
                paddingBottom="24px"
                component={Paper}
                elevation={3}
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#fff",
                  zIndex: 101,
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: "32px",
                    fontWeight: "700",
                    lineHeight: "38px",
                    letterSpacing: "0em",
                    textAlign: "left",
                  }}
                >
                  Orders
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <AddEntry />
                </Stack>
              </Stack>
              <Grid
                container
                px="48px"
                py="32px"
                style={{
                  backgroundColor: "transparent !important",
                  zIndex: 100,
                }}
                spacing={4}
              >
                {entries.map((item, index) => (
                  <Card key={index} {...item} />
                ))}
              </Grid>
            </Grid>
          </>
        ) : (
          <AddEntry isNew={true} />
        )}
      </Loading>
    </Grid>
  );
}

export default Dashboard;
