import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

import {
  Button,
  FormControl,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import urls from "../../../utils/urls.json";
import { Link } from "react-router-dom";

import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import TabularData from "./TabularData";
import { useParams } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { setNotify } from "../../../redux/utils/utilsSlice";

function Issue() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sendEmailDialog, setSendEmailDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState();

  const [sendingEmail, setSendingEmail] = useState(false);
  const [reSubmitting, setReSubmitting] = useState(false);

  // BREADCRUMBS
  const breadcrumbs = [
    <Link key="1" to={urls.issues.path} style={{ color: "#969696" }}>
      Issues
    </Link>,
    <Link key="2" to={urls.issue.path} style={{ color: "#969696" }}>
      Manage Issues
    </Link>,
  ];

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("issues"));
  }, [dispatch]);

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/support/issues/tests/`,
      params: {
        issue: id,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        const tempData = res.data.results.map((item) => ({
          id: item.test.id,
          testId: item.test.id,
          z3pulseTestId: item.test.z3pulse_test_id,
          status: item.test.status,
        }));
        setData(tempData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [id]);

  const handleResubmit = (id) => {
    setReSubmitting(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/support/resubmit-test/${id}`,
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        // notify
        if (selectedRows.length > 1) {
          window.location.reload();
        }
        let updatedSelectedRows = [];
        setData(
          data.map((value) => {
            updatedSelectedRows = [...updatedSelectedRows];
            if (value.id === res.data.id) {
              return {
                ...value,
                status: res.data.status,
              };
            }
            selectedRows.map((row) => {
              if (row === value.id) {
                updatedSelectedRows = [...updatedSelectedRows, row];
              }
              return "";
            });
            return value;
          })
        );
        setSelectedRows(updatedSelectedRows);
        setReSubmitting(false);
        dispatch(
          setNotify({
            open: true,
            action: `Test ID #${id} re-submitted`,
            severity: "success",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setReSubmitting(false);
        // notify
        dispatch(
          setNotify({
            open: true,
            action: `Failed to re-submitted #${id}`,
            severity: "error",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    setSendingEmail(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/support/manual-email`,
      headers: {
        Authorization: getAuthorization(),
      },
      data: {
        body: message,
        subject: subject,
        tests: selectedRows,
      },
    })
      .then((res) => {
        // notify
        setSendingEmail(false);
        dispatch(
          setNotify({
            open: true,
            action: `Email Sent`,
            severity: "success",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        setSubject("");
        setMessage("");
        setSendEmailDialog(false);
      })
      .catch((err) => {
        setSendingEmail(false);
        // notify
        dispatch(
          setNotify({
            open: true,
            action: `Send Email can't be sent`,
            severity: "error",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const handleResubmitList = (ids) => {
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      handleResubmit(id);
    }
  };

  return (
    <Grid
      container
      style={{
        height: "100%",
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      <Loading loading={loading}>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#fff",
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={3}
            px="58px"
            paddingTop="48px"
            paddingBottom="24px"
            component={Paper}
            elevation={3}
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "#fff",
              zIndex: 101,
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              {data.title}
            </Typography>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <Grid
            container
            px="48px"
            py="32px"
            mt={1}
            style={{
              backgroundColor: "transparent !important",
            }}
            spacing={4}
          >
            <TabularData
              data={data}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </Grid>
          <Stack
            direction="row"
            justifyContent="space-between"
            px="48px"
            py="12px"
          >
            <Button
              variant="contained"
              onClick={() => setSendEmailDialog(true)}
              disabled={!Boolean(selectedRows.length)}
            >
              Send Mail
            </Button>
            <CustomDialog
              open={sendEmailDialog}
              setOpen={setSendEmailDialog}
              title="Send Email"
              content={
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  spacing={6}
                  pt="15px"
                >
                  <form onSubmit={handleSendEmail} autoComplete="off">
                    <Stack my={2} direction="column" spacing={2}>
                      <FormControl>
                        <CustomInputLabel label="Subject" />
                        <CustomTextField
                          value={subject}
                          placeholder="Enter Subject"
                          required
                          fullWidth
                          onChange={(e) => {
                            setSubject(e.target.value);
                          }}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>
                      <FormControl>
                        <CustomInputLabel label="Message" />
                        <CustomTextField
                          value={message}
                          placeholder="Write your message..."
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          required
                          multiline
                          fullWidth
                          rows={10}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>

                      <Button
                        disabled={!(subject && message) || sendingEmail}
                        disableElevation
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                      >
                        {sendingEmail ? "Sending" : "Send"}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              }
            />

            <Button
              variant="contained"
              disabled={reSubmitting || !Boolean(selectedRows.length)}
              onClick={() => {
                handleResubmitList(selectedRows);
              }}
            >
              Resubmit
            </Button>
          </Stack>
        </Grid>
      </Loading>
    </Grid>
  );
}

export default Issue;
